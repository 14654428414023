
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('externalLrcpn.foreign_company_enlistment') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                          {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(phone_no)="data">
                                        <slot v-if="data.item.phone_no">
                                          {{ ($i18n.locale === 'bn' ? '০' : '0') }}{{ $n(data.item.phone_no, { useGrouping:false})  }}
                                        </slot>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </b-button>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('externalLrcpn.foreign_company_enlistment_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { foreignCompanyList, foreignCompanyToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      rows: [],
      item: '',
      dtlsItemId: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    formTitle () {
      return (this.editItemId === 0) ? this.$t('externalLrcpn.foreign_company_enlistment') + ' ' + this.$t('globalTrans.entry') : this.$t('externalLrcpn.foreign_company_enlistment') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('externalLrcpn.country_name'), class: 'text-left' },
          { label: this.$t('foreignCompany.organization_name'), class: 'text-left' },
          { label: this.$t('externalLrcpn.phone_no'), class: 'text-left' },
          { label: this.$t('externalLrcpn.email_address'), class: 'text-left' },
          { label: this.$t('externalLrcpn.address'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'country_name_bn' },
          { key: 'org_name' },
          { key: 'phone_no' },
          { key: 'email' },
          { key: 'address' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'country_name' },
          { key: 'org_name' },
          { key: 'phone_no' },
          { key: 'email' },
          { key: 'address' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    remove (item) {
      this.changeStatus(licenseRegistrationServiceBaseUrl, foreignCompanyToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, foreignCompanyList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const countryList = this.$store.state.ExternalLrcpn.commonObjCommonConfig.countryList.filter(item => item.status === 0)
      const listData = data.map(item => {
        const countryObj = countryList.find(country => country.value === item.country_id)
        const generateData = {
          country_name: countryObj !== undefined ? countryObj.text_en : '',
          country_name_bn: countryObj !== undefined ? countryObj.text_bn : ''
        }
        return Object.assign({}, item, generateData)
      })
      return listData
    }
  }
}
</script>
